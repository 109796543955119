import { Button } from 'easyship-components'

interface MoveActionsProps {
  isContainerToContainer?: boolean
  packageCount: number | null
  onHandleMoveAllPackages: () => void
  onHandleCancelMove: () => void
}

export default function MoveActions({
  isContainerToContainer,
  packageCount,
  onHandleCancelMove,
  onHandleMoveAllPackages,
}: MoveActionsProps) {
  return (
    <div className="absolute bottom-0 flex w-full items-center justify-between border-t border-sky-700 bg-sky-300 px-5 py-2 ">
      <Button className="h-10" color="danger" onClick={onHandleCancelMove}>
        cancel move
      </Button>
      {isContainerToContainer ? (
        <Button
          data-testid="move_all_packages_button"
          className="h-10"
          disabled={!packageCount}
          color="primary"
          onClick={onHandleMoveAllPackages}
        >
          Move {packageCount} packages
        </Button>
      ) : (
        <div className="flex items-center text-base text-black">
          Package count:
          <span className="ml-1 text-2xl font-bold text-black">{packageCount}</span>
        </div>
      )}
    </div>
  )
}
