import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

export default function useScanDetailsQuery(barcode: string) {
  const { moveItemsGateway } = useGateways()

  const query = useQuery({
    queryKey: ['getScanDetails', barcode],
    queryFn: () => moveItemsGateway.getScanDetails(barcode),
    enabled: !!barcode,
    cacheTime: 0,
  })

  return query
}
