import { useEffect, useRef, useState } from 'react'

import useDebounce from '@/hooks/useDebounce'
import { set } from 'zod'

interface ScanParams {
  enabled?: boolean
}

export function useScanWithTimestamp(params?: ScanParams): { value: string; time: number } {
  const { enabled = true } = params ?? {}
  const [scanValue, setScanValue] = useState({ value: '', time: 0 })
  const scanValueDebounced = useDebounce(scanValue, 300)
  const resetRef = useRef(false)

  useEffect(() => {
    function handleKeystroke(e: KeyboardEvent) {
      const character = e.key
      const timestamp = Date.now()
      if (resetRef.current) {
        resetRef.current = false

        setScanValue({ value: character, time: Date.now() })
      } else {
        setScanValue((prev) => ({
          value: prev.value + character,
          time: timestamp,
        }))
      }
    }

    enabled && document.addEventListener('keypress', handleKeystroke)
    return () => document.removeEventListener('keypress', handleKeystroke)
  }, [enabled])

  useEffect(() => {
    resetRef.current = true
  }, [scanValueDebounced])

  return scanValueDebounced
}
