import { ScanBarcode } from 'easyship-components/icons'

interface MoveInstructionsProps {
  hideIcon?: boolean
}
export default function MoveInstructions({ hideIcon }: MoveInstructionsProps) {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      {!hideIcon && <ScanBarcode height={72} width={72} />}
      <div className="mt-5">
        <p className="text-lg font-bold text-black">To move packages to a container:</p>
        <p className="text-lg text-black">Scan 1 or more packages, then a container</p>
      </div>
      <div className="mt-5">
        <p className="text-lg font-bold text-black">
          To move all packages from container to container:
        </p>
        <p className="text-lg text-black">Scan container A (from), then container B (to)</p>
      </div>
      <div className="mt-5">
        <p className="text-lg font-bold text-black">To move a container to a floor area:</p>
        <p className="text-lg text-black">Scan container, then floor area</p>
      </div>
    </div>
  )
}
