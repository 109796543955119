import { useState } from 'react'
import Link from '@mui/material/Link'
import { PackageItem } from '@/core/models/Package'
import { Button } from 'easyship-components'
import { ChevronDownward, ChevronUpward } from 'easyship-components/icons'

interface ItemsListProps {
  items: PackageItem[]
}

export default function ItemsList({ items }: ItemsListProps) {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded((prev) => !prev)
  }

  return (
    <div>
      <ol style={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
        {items.slice(0, expanded ? items.length : 2).map((item) => (
          <li key={item.ebayItemId}>
            <Link href={item.url} target="_blank" rel="noopener">
              {item.description}
            </Link>{' '}
            x{item.quantity}
            <div>Item ID: {item.sku || '-'}</div>
          </li>
        ))}
      </ol>
      {items.length > 2 && (
        <Button
          color="primary"
          flat
          rightIcon={expanded ? <ChevronUpward /> : <ChevronDownward />}
          onClick={handleClick}
        >
          View {expanded ? 'Less' : 'More'}
        </Button>
      )}
    </div>
  )
}
