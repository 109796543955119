import ErrorIcon from '@mui/icons-material/Error'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import { usePackage } from '../PackageProvider'

export default function DangerousProhibitedInspection() {
  const {
    pack,
    currentInspection,
    dismissFlag,
    confirmFlag,
    confirmCurrentInspection,
    dismissCurrentInspection,
  } = usePackage()
  const [comment, setComment] = useState('')
  const shortFlaggedForName =
    pack.flaggedFor[0] === 'dangerous' || currentInspection?.category === 'dangerous'
      ? 'Dangerous Goods'
      : 'Prohibited Goods'
  const dismissButtonLabel =
    pack.flaggedFor.length > 1 ? 'dismiss and\ncheck next flag' : 'process forward'

  return (
    <>
      <Stack direction="row" spacing={1}>
        <ErrorIcon color="error" />
        <Typography variant="h1">Confirm {shortFlaggedForName}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <div className="flex flex-col gap-1">
          {pack.screeningFlags
            .reduce((uniqueTitles: string[], flag) => {
              if (!uniqueTitles.includes(flag.itemTitle)) {
                uniqueTitles.push(flag.itemTitle)
              }
              return uniqueTitles
            }, [])
            .map((title) => (
              <div className="text-lg font-bold underline" key={title}>
                {title}
              </div>
            ))}
        </div>
      </Stack>

      <TextField
        inputProps={{ 'data-hj-allow': true }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Comments"
        rows={2}
        multiline
      />
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() =>
            currentInspection ? dismissCurrentInspection(comment) : dismissFlag(comment)
          }
          variant="contained"
          color="secondary"
          sx={{ height: 80, whiteSpace: 'pre' }}
          fullWidth
        >
          {dismissButtonLabel}
        </Button>
        <Button
          onClick={() =>
            currentInspection ? confirmCurrentInspection(comment) : confirmFlag(comment)
          }
          variant="contained"
          color="error"
          sx={{ height: 80 }}
          disabled={!comment}
          fullWidth
        >
          {shortFlaggedForName} confirmed
        </Button>
      </Stack>
    </>
  )
}
