import { Dialog, DialogContent } from '@mui/material'
import { Button } from 'easyship-components'

interface CancelConfirmationProps {
  open: boolean
  onDismiss: () => void
  onCancel: () => void
}
export default function CancelConfirmation({ open, onDismiss, onCancel }: CancelConfirmationProps) {
  return (
    <Dialog open={open} fullScreen>
      <DialogContent className="m-auto flex max-w-sm flex-col justify-center">
        <div className="mb-10 text-center text-lg text-black">
          Cancelling a move will remove all packages or containers scanned.
        </div>
        <div className="flex justify-between">
          <Button onClick={onDismiss} color="default">
            dismiss
          </Button>
          <Button onClick={onCancel} color="danger">
            confirm cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
