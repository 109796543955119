import { useScanWithTimestamp } from '@/hooks/useScanWithTimestamp'
import MoveInstructions from './components/MoveInstructions'
import Loading from '@/components/Loading'
import MoveCard from './components/MoveCard'
import { ScanDetails } from './models/MoveItems'
import { useEffect } from 'react'

interface scanContainerProps {
  itemsScanned: ScanDetails[]
  isFetching: boolean
  onScannedBarcode: (barcode: { value: string; time: number }) => void
}

export default function ScanContainer({
  itemsScanned,
  isFetching,
  onScannedBarcode,
}: scanContainerProps) {
  const barcode = useScanWithTimestamp()
  useEffect(() => {
    if (barcode.value) {
      onScannedBarcode(barcode)
    }
  }, [barcode])
  return (
    <>
      {itemsScanned.length === 0 && (
        <div className="flex w-full justify-center px-5">
          <MoveInstructions />
        </div>
      )}
      {itemsScanned.length > 0 && (
        <div className="flex h-inherit w-full flex-col overflow-y-scroll ">
          {itemsScanned?.map((item, index) => (
            <div key={index}>
              <MoveCard scannedItem={item} />
            </div>
          ))}

          {!isFetching && (
            <div className="p-5 text-center text-lg">
              {itemsScanned[0]?.type === 'Package'
                ? 'To complete the move, scan a container barcode. To remove a package, scan the package again.'
                : 'Scan a different container to move all the packages to that container OR scan a floor to move the container to the area.'}
            </div>
          )}
          {isFetching && <Loading />}
        </div>
      )}
    </>
  )
}
