import { ScreeningFlagCategory } from '@/gateways/api/models/Package'

export default interface PreInspectionPackage {
  id: string
  easyshipShipmentId: string
  items: PreInspectionPackageItems[]
  screeningFlags: PreInspectionPackageScreeningFlags[]
}

export interface PreInspectionPackageItems {
  ebayItemId: string
  description: string
  listingUrl: string
  quantity: number
  sku: number
}

export interface PreInspectionPackageScreeningFlags {
  id: number
  category: ScreeningFlagCategory
  keyword: string
  itemTitle: string
}

export enum PreInspectionActionOutcome {
  TO_INSPECT = 'to_inspect',
  DISMISSED = 'dismissed',
}
