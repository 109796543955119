import {
  BinScanAttributes,
  FloorAreaScanAttributes,
  PackageScanAttributes,
  ScanDetails,
  ScanType,
} from '@/MoveModule/models/MoveItems'
import { ScanDetailsDataResponse, ScanResponseAttributes } from '../models/MoveItems'

export function mapFromApi(scanDetails: ScanDetailsDataResponse): ScanDetails {
  return {
    type: scanDetails.type,
    attributes: mapAttributes(scanDetails.type, scanDetails.attributes),
  }
}

function mapAttributes(
  type: ScanType,
  attributes: ScanResponseAttributes,
): PackageScanAttributes | BinScanAttributes | FloorAreaScanAttributes {
  if (type === 'Package') {
    return {
      id: attributes.id as string,
      ebayPackageId: attributes.ebay_package_id,
      warehouseState: attributes.warehouse_state,
      container: attributes.container,
      packageTags: attributes.package_tags,
    } as PackageScanAttributes
  } else if (type === 'Bin') {
    return {
      name: attributes.name,
      barcode: attributes.barcode,
      displayName: attributes.display_name,
      id: attributes.id as number,
      floorArea: attributes.floor_area,
      binType: attributes.bin_type,
      packagesCount: attributes.packages_count,
    } as BinScanAttributes
  } else {
    return {
      name: attributes.name,
      purpose: attributes.purpose,
      id: attributes.id as number,
    } as FloorAreaScanAttributes
  }
}
