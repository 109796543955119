import Box from '@mui/material/Box'
import Link from '@mui/material/Link'

import COLORS from '@/colors'
import Stack from '@/components/Stack'
import { Stat, StatLabel, StatValue } from '@/components/Stat'

import PreInspectionPackage from './models/PreInspection'
import { Button } from 'easyship-components'
import { ChevronDownward, ChevronUpward } from 'easyship-components/icons'
import { useState } from 'react'

interface PackageDetailsProps {
  pack?: PreInspectionPackage
}

export default function PreInspectionPackageDetails({ pack }: PackageDetailsProps) {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded((prev) => !prev)
  }

  return (
    <Stack direction="row">
      <Box flex={1} gap={1}>
        <Stat horizontal>
          <StatLabel variant="h3">Package ID</StatLabel>
          <StatValue variant="h4">{pack?.easyshipShipmentId}</StatValue>
        </Stat>
        <Stat horizontal>
          <StatLabel variant="h3">Items</StatLabel>
          <StatValue variant="h4">
            <ol style={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
              {pack?.items?.map((item) => (
                <li key={item.ebayItemId}>
                  <Link
                    href={item.listingUrl}
                    target="_blank"
                    rel="noopener"
                    color={COLORS.blueNormal}
                  >
                    {item.description}
                  </Link>{' '}
                  x{item.quantity}
                </li>
              ))}
            </ol>
            {pack && pack.items?.length > 2 && (
              <Button
                color="primary"
                flat
                rightIcon={expanded ? <ChevronUpward /> : <ChevronDownward />}
                onClick={handleClick}
              >
                View {expanded ? 'Less' : 'More'}
              </Button>
            )}
          </StatValue>
        </Stat>
      </Box>
    </Stack>
  )
}
