import { CourierReturn } from '@/CourierReturn/models/CourierReturn'
import {
  BatteryInspectionClassification,
  BatteryInspectionSticker,
  BatteryInspectionResult,
  BatteryUnshippableReason,
  LabelState,
  ScreeningFlagCategory,
  ScreeningFlagStatus,
  WarehouseState,
  BinType,
  DimensionUnit,
  WeightUnit,
  CourierIdentifier,
  packageTags,
} from '@/gateways/api/models/Package'

import Inspection from './Inspection'
import { ReceiverAddress } from './ReceiverAddress'
import { Repack } from './Repack'

export interface PackageWeight {
  value: number
  unit: WeightUnit
}

export interface ScreeningFlag {
  status: ScreeningFlagStatus
  category: ScreeningFlagCategory
  keyword: string
  operatingProcedures: string
  guidelines: string
  comments: string
  itemTitle: string
}

export interface PackageItem {
  ebayItemId: string
  description: string
  url: string
  quantity: number
  sku: string
}

export interface BatteryInspectionOutcome {
  result: BatteryInspectionResult
  shippingDocumentInstructions: string[]
  stickers: BatteryInspectionSticker[]
  unshippableReasons: BatteryUnshippableReason[]
  classifications: BatteryInspectionClassification[]
}

export interface PackageWarehouseState {
  value: WarehouseState
  displayName: string
}

export interface PackageBin {
  barcode: string
  name: string
  displayName: CourierIdentifier
  type: BinType
}

export default interface Package {
  id: string
  ebayPackageId: string
  easyshipShipmentId: string
  firstMileTrackingNumber: string
  lastMileTrackingNumber: string
  items: PackageItem[]
  warehouseState: PackageWarehouseState
  lastMileCourierName: string
  flaggedFor: ScreeningFlagCategory[]
  screeningFlags: ScreeningFlag[]
  inspections: Inspection[]
  batteryInspectionOutcome: BatteryInspectionOutcome
  labelState: LabelState
  evtn: string
  senderName: string
  receiverAddress: ReceiverAddress
  bin: PackageBin | null
  weight: PackageWeight
  dimensions: PackageDimensions
  combinedShipment: boolean
  courierReturn: CourierReturn | null
  repack: Repack | null
  multipack: boolean
  packageTags: packageTags[]
  scanReceived: string
  lastStationId: number
  processedCourierReturnCount: number
}

export interface RemovePackagesResult {
  succeededPackageIds: string[]
  failedBarcodes: string[]
  errorMessage: string
}

export interface PackageDimensions {
  length: number
  width: number
  height: number
  unit: DimensionUnit
}

export interface PackageMeasurementsMetadata {
  measurementId: number
  scannedOn: string
}

export interface PackageMeasurements extends PackageMeasurementsMetadata {
  weight: PackageWeight
  dimensions: PackageDimensions
}

export const LABEL_STATE_TITLE: Readonly<Record<LabelState, string>> = {
  not_created: 'not created',
  processing: 'processing',
  generated: 'generated',
  no_rates: 'no rates',
  failed: 'failed',
  technical_failed: 'technical failed',
  reported: 'reported',
  voided: 'voided',
  void_failed: 'void failed',
}
