import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

interface MovePackagesToContainerMutationParams {
  sourceBinId: number
  destinationBinId: number
}

export function useMoveAllPackagesToContainerMutation() {
  const { moveItemsGateway } = useGateways()

  return useMutation({
    mutationFn: ({ sourceBinId, destinationBinId }: MovePackagesToContainerMutationParams) =>
      moveItemsGateway.moveAllPackagesToContainer(sourceBinId, destinationBinId),
    onError: (reason) => toastApiError(reason),
  })
}
