import {
  BinScanAttributes,
  FloorAreaScanAttributes,
  PackageScanAttributes,
  ScanDetails,
  ScanType,
} from '../models/MoveItems'

interface PackageCardProps {
  attributes: PackageScanAttributes
}
interface BinCardProps {
  attributes: BinScanAttributes
}
interface FloorAreaCardProps {
  attributes: FloorAreaScanAttributes
}

interface MoveCardProps {
  scannedItem: ScanDetails
}

export default function MoveCard({ scannedItem }: MoveCardProps) {
  const getBackgroundColor = (type: ScanType): string => {
    const scannedItemColor = {
      Package: 'bg-white',
      Bin: 'bg-yellow-100',
      FloorArea: 'bg-teal-200',
    }
    return scannedItemColor[type]
  }

  const getFieldsForType = (type: ScanType) => {
    switch (type) {
      case 'Package':
        return [
          { label: 'Package Id', key: 'ebayPackageId' },
          { label: 'State', key: 'warehouseState' },
          { label: 'Tags', key: 'packageTags' },
          { label: 'Container', key: 'container' },
        ]
      case 'Bin':
        return [
          { label: 'Container Name', key: 'name' },
          { label: 'Container Type', key: 'binType' },
          { label: 'Location', key: 'floorArea.name' },
        ]
      case 'FloorArea':
        return [
          { label: 'Floor Name', key: 'name' },
          { label: 'Floor Purpose', key: 'purpose' },
        ]
      default:
        return []
    }
  }
  function getNestedValue(obj: any, key: string) {
    return key.split('.').reduce((o, i) => (o ? o[i] : undefined), obj)
  }

  return (
    <div
      className={`flex w-full items-start border-b border-sky-700  ${getBackgroundColor(
        scannedItem?.type,
      )} px-4 py-2`}
    >
      <div>
        {getFieldsForType(scannedItem.type).map((field) => (
          <div className="flex gap-2" key={field.key}>
            <div className="font-bold">{field.label}:</div>
            <div>{getNestedValue(scannedItem.attributes, field.key)}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
