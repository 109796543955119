import BinDetails from '@/core/models/BinDetails'

import { BinDetailsDataResponse } from '../models/Bin'

export function mapFromApi(binDetails: BinDetailsDataResponse): BinDetails {
  return {
    id: binDetails.id,
    name: binDetails.name,
    processStage: binDetails.process_stage,
    typeName: binDetails.sort_type.name,
    droppedAt: binDetails.dropped_at,
    displayName: binDetails.display_name,
    floorLocation: {
      id: binDetails.floor_area.id,
      name: binDetails.floor_area.name,
    },
    packagesWeight: {
      value: binDetails.packages_weight,
      unit: binDetails.packages_weight_unit,
    },
    packagesCount: binDetails.packages_count,
    oldestPackageAddedDateTime: binDetails.oldest_package_added_at,
    packages: binDetails.packages.map((pack) => ({
      id: pack.id,
      evtn: pack.evtn,
      easyshipShipmentId: pack.easyship_shipment_id,
      lastMileTrackingNumber: pack.last_mile_tracking_number,
      warehouseState: {
        value: pack.warehouse_state,
        displayName: pack.warehouse_state_display_name,
      },
      scanReceivedDateTime: pack.scan_received_at,
      items: pack.items.map((item) => ({
        ebayItemId: item.ebay_item_id,
        description: item.description,
        url: item.listing_url,
        quantity: item.quantity,
      })),
    })),
  }
}
