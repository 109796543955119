import PreInspectionPackage from '@/preInspection/models/PreInspection'

import { PreInspectionPackageDataResponse } from '../models/PreInspection'

export function mapFromApi(preInspectPack: PreInspectionPackageDataResponse): PreInspectionPackage {
  return {
    id: preInspectPack.id,
    easyshipShipmentId: preInspectPack.easyship_shipment_id,
    items: preInspectPack.items.map((item) => ({
      description: item.description,
      ebayItemId: item.ebay_item_id,
      listingUrl: item.listing_url,
      quantity: item.quantity,
      sku: item.sku,
    })),
    screeningFlags: preInspectPack.screening_flags.map((flag) => ({
      category: flag.category,
      id: flag.id,
      keyword: flag.keyword,
      itemTitle: flag.item_title,
    })),
  }
}
