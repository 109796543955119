import { Grid } from '@mui/material'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Button } from 'easyship-components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AppBar from '../../components/AppBar'
import Spacer from '../../components/Spacer'
import { useSession } from '../../contexts/session'
import { useFeatureFlags } from '@/contexts/featureFlags'

interface ButtonConfig {
  label: string
  path: string
  isEnabled?: boolean
}

interface Category {
  label: string
  buttons: ButtonConfig[]
}

export default function HomePage() {
  const navigate = useNavigate()
  const { logout } = useSession()
  const [isLogoutLoading, setIsLogoutLoading] = useState(false)
  const { isFeatureEnabled } = useFeatureFlags()

  async function handleLogout() {
    setIsLogoutLoading(true)
    try {
      await logout()
    } finally {
      setIsLogoutLoading(false)
    }
    navigate('/login')
  }

  const categories: Category[] = [
    {
      label: 'Inbound',
      buttons: [
        { label: 'induction', path: '/induction' },
        { label: 'automation processing', path: '/label-processing' },
      ],
    },
    {
      label: 'Outbound',
      buttons: [
        { label: 'courier sort', path: '/courier-sort' },
        { label: 'x-ray', path: '/x-ray' },
        { label: 'manifest', path: '/manifest' },
        { label: 'manifest V2', path: '/manifest-v2' },
      ],
    },
    {
      label: 'Containers',
      buttons: [
        { label: 'view containers', path: '/view-containers' },
        {
          label: 'move items',
          path: '/move',
          isEnabled: isFeatureEnabled('EX2_903_move_module'),
        },
      ],
    },
    {
      label: 'Screening',
      buttons: [{ label: 'pre-inspection', path: '/pre-inspection' }],
    },
  ]
  const renderButtons = (buttons: ButtonConfig[]) => (
    <Stack spacing={2}>
      {buttons.map(
        ({ isEnabled = true, label, path }, index) =>
          isEnabled && (
            <Button
              className="lg:h-[60px]"
              key={index}
              onClick={() => navigate(path)}
              color="default"
            >
              {label}
            </Button>
          ),
      )}
    </Stack>
  )

  return (
    <>
      <AppBar>
        <Spacer />
        <Button flat color="primary" onClick={handleLogout} loading={isLogoutLoading}>
          log out
        </Button>
      </AppBar>
      <Container maxWidth="lg" sx={{ my: 2 }}>
        <Stack spacing={2} alignItems="center">
          <Button onClick={() => navigate('/scan')} color="primary" className="w-full">
            scan package
          </Button>

          <Grid width="100%" container spacing={2}>
            {categories.map((category, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Typography textAlign="center" variant="h6" sx={{ mb: 1 }}>
                  {category.label}
                </Typography>
                {renderButtons(category.buttons)}
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
    </>
  )
}
