import { WarehouseState, packageTags } from '@/gateways/api/models/Package'
import { Scan } from 'easyship-components/icons'

export interface ScanDetails {
  type: ScanType
  attributes: PackageScanAttributes | BinScanAttributes | FloorAreaScanAttributes
}

export type ScanType = 'Package' | 'Bin' | 'FloorArea'

// export type ScanAttributes =
export interface PackageScanAttributes {
  id: string
  ebayPackageId: string
  warehouseState: string
  container: string
  packageTags: packageTags[]
}

export interface BinScanAttributes {
  name: string
  barcode: string
  displayName: string
  id: number
  floorArea: floorArea
  binType: string
  packagesCount: number
}

export interface FloorAreaScanAttributes {
  name: string
  purpose: string
  id: number
}

export interface floorArea {
  id: string
  name: string
}

export enum ScanTypeEnum {
  Package = 'Package',
  Bin = 'Container',
  FloorArea = 'FloorArea',
}
