import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

interface MovePackagesToContainerMutationParams {
  sourceBinId: number
  floorAreaId: number
}

export function useMoveContainerMutation() {
  const { moveItemsGateway } = useGateways()

  return useMutation({
    mutationFn: ({ sourceBinId, floorAreaId }: MovePackagesToContainerMutationParams) =>
      moveItemsGateway.moveContainer(sourceBinId, floorAreaId),
    onError: (reason) => toastApiError(reason),
  })
}
