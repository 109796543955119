import { Dialog, DialogContent } from '@mui/material'
import { Button } from 'easyship-components'
import { ScanDetails, ScanType, ScanTypeEnum } from '../models/MoveItems'

interface RemoveConfirmationProps {
  open: boolean
  onDismiss: () => void
  onRemove: () => void
  scannedItem: ScanDetails
}
export default function RemoveConfirmation({
  open,
  onDismiss,
  onRemove,
  scannedItem,
}: RemoveConfirmationProps) {
  const getFieldsForType = (type: ScanType) => {
    switch (type) {
      case 'Package':
        return [
          { label: 'Package Id', key: 'ebayPackageId' },
          { label: 'State', key: 'warehouseState' },
          { label: 'Tags', key: 'packageTags' },
        ]
      case 'Bin':
        return [
          { label: 'Container Name', key: 'name' },
          { label: 'Container Type', key: 'binType' },
          { label: 'Location', key: 'floorArea.name' },
        ]
      case 'FloorArea':
        return [
          { label: 'Floor Name', key: 'name' },
          { label: 'Floor Purpose', key: 'purpose' },
        ]
      default:
        return []
    }
  }
  function getNestedValue(obj: any, key: string) {
    return key.split('.').reduce((o, i) => (o ? o[i] : undefined), obj)
  }

  return (
    <Dialog open={open} fullScreen>
      <DialogContent className="m-auto flex max-w-sm flex-col justify-center">
        <div className="mb-10 text-center text-lg text-black">
          You have already scanned this{' '}
          <span className="lowercase">{ScanTypeEnum[scannedItem?.type]}</span>. Do you want to
          remove it from the list?
        </div>
        <div>
          {getFieldsForType(scannedItem?.type).map((field) => (
            <div className="flex justify-between gap-2" key={field.key}>
              <div className="font-bold">{field.label}:</div>
              <div>{getNestedValue(scannedItem?.attributes, field.key)}</div>
            </div>
          ))}
        </div>
        <div className="mt-5 flex justify-between">
          <Button onClick={onDismiss} color="default">
            dismiss
          </Button>
          <Button onClick={() => onRemove()} color="danger">
            remove package
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
