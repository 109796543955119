import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'

import Button from '@/components/Button'
import Dialog, { DialogProps } from '@/components/Dialog'
import Select from '@/components/Select'
import { toastApiError } from '@/components/Toastify'
import Bin from '@/core/models/Bin'
import useBins from '@/core/useBins'
import useBinFloorLocationsQuery from '@/hooks/useBinFloorLocationsQuery'
import useBinTypesQuery from '@/hooks/useBinTypesQuery'

interface NewBinDialogProps extends Omit<DialogProps, 'title'> {
  onNewBinCreated: (bin: Bin) => void
}

interface NewBinEditFormData {
  binTypeId: string
  binRuleId: number | ''
  floorLocationId: number | ''
}

export interface NewBinFormData {
  binTypeId: string
  binRuleId: number | ''
  floorLocationId: number
}

const defaultBinFormData: NewBinEditFormData = {
  binTypeId: '',
  binRuleId: '',
  floorLocationId: '',
}

export default function NewBinDialog({ onNewBinCreated, ...props }: NewBinDialogProps) {
  const [formData, setFormData] = useState<NewBinEditFormData>(defaultBinFormData)
  const { createNewBin } = useBins()
  const { data: binTypes = [] } = useBinTypesQuery()
  const { data: binFloorLocations = [] } = useBinFloorLocationsQuery()
  const [isLoading, setIsLoading] = useState(false)
  const binTypeSelected = binTypes.find((binType) => binType.id === formData.binTypeId)
  const isFormValid = validateFormData()

  function validateFormData() {
    const hasBinSelectedWithRules = !!binTypeSelected && binTypeSelected.rules.length > 0

    return (
      formData.binTypeId !== '' &&
      formData.floorLocationId !== '' &&
      !(hasBinSelectedWithRules && formData.binRuleId === '')
    )
  }

  useEffect(() => {
    props.open && setFormData(defaultBinFormData)
  }, [props.open])

  function updateFormData(updates: Partial<NewBinEditFormData>) {
    setFormData({ ...formData, ...updates })
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (isFormValid) {
      setIsLoading(true)
      try {
        const newBin = await createNewBin(formData as NewBinFormData)
        onNewBinCreated(newBin)
      } catch (reason) {
        toastApiError(reason)
      } finally {
        setIsLoading(false)
        props.onClose?.()
      }
    }
  }

  return (
    <Dialog title="New Bin" {...props} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <Stack spacing={3}>
            <Select
              label="Bin Type"
              value={formData.binTypeId}
              onChange={(e) => updateFormData({ binTypeId: e.target.value })}
            >
              {binTypes.map((binType) => (
                <MenuItem key={binType.id} value={binType.id}>
                  {binType.name}
                </MenuItem>
              ))}
            </Select>
            {binTypeSelected && binTypeSelected.rules.length > 0 && (
              <Select
                label="Bin Rule"
                value={formData.binRuleId}
                onChange={(e) => updateFormData({ binRuleId: +e.target.value })}
              >
                {binTypeSelected.rules.map((rule) => (
                  <MenuItem key={rule.id} value={rule.id}>
                    {rule.name}
                  </MenuItem>
                ))}
              </Select>
            )}

            <Select
              label="Floor Location"
              value={formData.floorLocationId}
              onChange={(e) => updateFormData({ floorLocationId: +e.target.value })}
            >
              {binFloorLocations.map((binFloorLocation) => (
                <MenuItem key={binFloorLocation.id} value={binFloorLocation.id}>
                  {binFloorLocation.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Button
            type="submit"
            variant="contained"
            sx={{ alignSelf: 'center', height: 60 }}
            loading={isLoading}
            disabled={!isFormValid}
          >
            confirm & print barcode
          </Button>
        </Stack>
      </form>
    </Dialog>
  )
}
