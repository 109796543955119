import axios from 'axios'

import { ScanDetails } from '@/MoveModule/models/MoveItems'
import { ScanDetailsResponse } from './models/MoveItems'
import { mapFromApi } from './mappers/moveItemsMapper'
import MoveItemsGateway from '@/MoveModule/ports/moveItems'

function getScanDetails(barcode: string): Promise<ScanDetails> {
  return axios
    .get<ScanDetailsResponse>(`/v1/bin_manager/scan/${barcode}`)
    .then(({ data }) => mapFromApi(data.result.data))
}

function movePackagesToContainer(packageIds: string[], binId: number): Promise<void> {
  return axios.post(`/v1/bin_manager/package_movements`, {
    package_ids: packageIds,
    destination_bin_id: binId,
  })
}

function moveAllPackagesToContainer(sourceBinId: number, destinationBinId: number): Promise<void> {
  return axios.post(`/v1/bin_manager/bin_movements`, {
    source_bin_id: sourceBinId,
    destination_bin_id: destinationBinId,
  })
}

function moveContainer(sourceBinId: number, floorAreaId: number): Promise<void> {
  return axios.post(`/v1/bin_manager/floor_area_movements`, {
    bin_id: sourceBinId,
    floor_area_id: floorAreaId,
  })
}

export default function createApiMoveItemsGateway(): MoveItemsGateway {
  return {
    getScanDetails,
    movePackagesToContainer,
    moveAllPackagesToContainer,
    moveContainer,
  }
}
