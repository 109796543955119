import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import ZendeskForm from '@/components/ZendeskForm'
import { warehouseInspectionStates } from '@/gateways/api/models/Package'

import { usePackage } from '../PackageProvider'
import BatteryInspection from './BatteryInspection'
import DangerousProhibitedInspection from './DangerousProhibitedInspection'
import InspectionAlert from './InspectionAlert'

function InspectionForm() {
  const { pack, currentInspection, confirmHighValueGoods } = usePackage()
  const isBatteryInspection =
    pack.warehouseState.value === 'battery_inspection_required' ||
    (currentInspection?.status === 'categorized' && currentInspection?.category === 'battery')
  const showZendeskForm = pack.screeningFlags.some((flag) => flag.category === 'high_value')

  if (showZendeskForm) return <ZendeskForm onZendeskCompleted={confirmHighValueGoods} />
  if (isBatteryInspection) return <BatteryInspection />
  return <DangerousProhibitedInspection />
}

export default function PackageInspection() {
  const { pack, bin, currentInspection } = usePackage()
  const doesRequireInspection =
    warehouseInspectionStates.some((state) => state === pack.warehouseState.value) ||
    currentInspection?.status === 'categorized'
  const hasPlaceInBinVisible: boolean = !!pack.bin || !!bin

  if (!doesRequireInspection || hasPlaceInBinVisible) return null

  return (
    <Stack spacing={2} divider={<Divider light />}>
      {pack.screeningFlags
        .filter((flag, index, self) => index === self.findIndex((f) => f.keyword === flag.keyword))
        .map((screeningFlag) => (
          <InspectionAlert key={screeningFlag.keyword} screeningFlag={screeningFlag} />
        ))}
      <InspectionForm />
    </Stack>
  )
}
