import Chance from 'chance'

import Package from '../../../core/models/Package'

const chance = new Chance()

export const packageStub = (): Package => ({
  id: chance.guid(),
  ebayPackageId: 'ZYU89YT_ESUS12345678',
  easyshipShipmentId: 'EEUS000000147FR',
  scanReceived: '2022-10-20T10:53:06Z',
  lastStationId: 12,
  firstMileTrackingNumber: 'FE52048C9E7546FEB65C43846F34C758',
  lastMileTrackingNumber: 'LF043001547FR',
  combinedShipment: false,
  items: [
    {
      ebayItemId: '128392174102983',
      description: 'Android machine',
      url: 'https://ebay.com/192801297413',
      quantity: 1,
      sku: '364065960243',
    },
  ],
  warehouseState: { value: 'ready_for_xray', displayName: 'Ready for X-Ray' },
  lastMileCourierName: 'DHL eCommerce - Parcel Priority',
  flaggedFor: [],
  screeningFlags: [],
  inspections: [
    {
      id: '2',
      category: '',
      source: 'xray',
      status: 'requested',
    },
  ],
  batteryInspectionOutcome: {
    result: 'no_batteries',
    shippingDocumentInstructions: [],
    stickers: [],
    unshippableReasons: [],
    classifications: [],
  },
  labelState: 'not_created',
  evtn: 'bea423',
  senderName: 'Cory Will',
  receiverAddress: {
    fullName: 'Cuc Thompson',
    addressLine1: '9426 Celine Viaduct',
    addressLine2: 'Apt. 310',
    city: 'Emmerichton',
    state: 'New Jersey',
    postalCode: '43823-7778',
    countryCode: 'JO',
    country: 'CA',
  },
  bin: null,
  weight: {
    value: 10.33,
    unit: 'lb',
  },
  dimensions: {
    length: 10,
    width: 8.2,
    height: 6.4,
    unit: 'in',
  },
  courierReturn: null,
  repack: null,
  multipack: false,
  packageTags: [],
  processedCourierReturnCount: 0,
})
