import { Dialog, DialogContent } from '@mui/material'
import { Button } from 'easyship-components'
import MoveInstructions from './MoveInstructions'
import { NotSupported } from 'easyship-components/icons'

interface CancelConfirmationProps {
  open: boolean
  onConfirm: () => void
  barcode: string
}
export default function MoveErrorCard({ open, onConfirm, barcode }: CancelConfirmationProps) {
  return (
    <Dialog open={open} fullScreen>
      <DialogContent className="flex flex-col items-center justify-center bg-red-700">
        <NotSupported width="72px" height="72px" className="mb-5 fill-white" />
        <div className="mb-5 text-center text-2xl font-bold text-white">Move not supported</div>
        <div className="mb-5 text-center text-lg text-white">{barcode}</div>
        <div className="rounded-sm bg-red-100 p-3">
          <MoveInstructions hideIcon />
        </div>
        <div className="mt-5 flex">
          <Button onClick={onConfirm} className="w-full">
            confirm package not moved
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
