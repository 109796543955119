import { OrderDataResponse } from '@/searchResult/gateways/api/models/Order'
import Order from '@/searchResult/models/Order'

export function mapFromApi(order: OrderDataResponse): Order {
  return {
    id: order.id,
    evtn: order.evtn,
    isReturn: order.evtn.endsWith('_R'),
    /** @deprecated */
    senderName: order.ship_from.full_name ?? '',
    senderAddress: {
      fullName: order.ship_from.full_name ?? '',
      addressLine1: order.ship_from.address_line_1 ?? '',
      addressLine2: order.ship_from.address_line_2 ?? '',
      city: order.ship_from.city ?? '',
      state: order.ship_from.state ?? '',
      postalCode: order.ship_from.postal_code ?? '',
      countryCode: order.ship_from.country_code ?? '',
      country: order.ship_from.country ?? '',
    },
    receiverAddress: {
      fullName: order.ship_to.full_name ?? '',
      addressLine1: order.ship_to.address_line_1 ?? '',
      addressLine2: order.ship_to.address_line_2 ?? '',
      city: order.ship_to.city ?? '',
      state: order.ship_to.state ?? '',
      postalCode: order.ship_to.postal_code ?? '',
      countryCode: order.ship_to.country_code ?? '',
      country: order.ship_to.country ?? '',
    },
    items: order.items.map((item) => ({
      ebayItemId: item.ebay_item_id,
      description: item.description,
      url: item.listing_url,
      quantity: item.quantity,
      sku: item.sku ?? '',
    })),

    packages: order.packages.map((pack) => ({
      id: pack.id,
      ebayPackageId: pack.ebay_package_id,
      easyshipShipmentId: pack.easyship_shipment_id,
      firstMileTrackingNumber: pack.first_mile_tracking_number,
      lastMileTrackingNumber: pack.last_mile_tracking_number ?? '',
      warehouseState: {
        value: pack.warehouse_state,
        displayName: pack.warehouse_state_display_name,
      },
      scanReceived: pack.scan_received_at,
      lastStationId: pack.last_station_id,
      combinedShipment: pack.combined_shipment,
    })),
    combinedShipment: order.combined_shipment,
  }
}
