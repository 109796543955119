import Chance from 'chance'

import { CourierReturn } from '@/CourierReturn/models/CourierReturn'
import { ScanDetails } from '@/MoveModule/models/MoveItems'
import { string } from 'zod'

const chance = new Chance()

export const moveItemStub = (): ScanDetails => ({
  type: 'Package',
  attributes: {
    name: chance.name(),
    id: chance.integer({ min: 1, max: 100 }),
    barcode: chance.string(),
    purpose: chance.string(),
    ebayPackageId: chance.string(),
    binType: chance.string(),
    container: chance.string(),
    packagesCount: chance.integer({ min: 1, max: 100 }),
    floorArea: {
      id: chance.string(),
      name: chance.string(),
    },
    displayName: chance.string(),
    packageTags: [],
    warehouseState: 'to_be_liquidated',
  },
})
